<template>
  <div>
    <app-breadcrumb />
    <div class="page-container order-wrapper">
      <el-form ref="orderForm" :model="order" :rules="rules" label-width="140px" size="small">
        <div class="step1" v-if="activeIndex===0">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info" style="margin-bottom:10px;" />
          <el-form-item label="订货人编号:" prop="memberNo">
            <el-input style="max-width: 200px" v-model.trim="order.memberNo" clearable @blur.native.capture="handleNoCheck(order.memberNo)" />
            <span class="redspan">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNo }}</span>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onNext">下一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step2" v-show="activeIndex===1">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <div class="pane-item">顾客编号: {{ order.memberNo }}</div>
          <p></p>
<!--          <p style="font-size: 16px;color: red">促销小提示：11.16-12.6号期间，每达到400消费指数即赠送灵芝香菇蝙蝠蛾拟青霉口服液1盒，多买多赠，购买成功后订单详情中可查询到赠品，谢谢！</p>-->
          <el-alert v-if="!isAgent && isCustomer" type="error" title="普通顾客不能报折扣订货单" />
          <template v-if="isAgent || !isCustomer">
            <div class="pane-title">商品列表</div>
            <el-divider></el-divider>
            <div class="summary">
              <span>金额:<em>{{ amount }}</em></span>

              <span>消费指数:<em>{{ pv }}</em></span>
            </div>
            <product-selector v-if="activeIndex===1" v-model="order.details" :data="goods" :loading="goodsLoading" @changeData="productSelectorChange" />
<!--    TZ(CN)19012    女神套装   -->
            <div v-show="open">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择旋磁智感健康文胸产品尺码" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01057</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸70A/B</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details1.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01058</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸70C/D</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details2.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01059</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸75A/B</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details3.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01060</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸75C/D</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details4.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01061</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸80A/B</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details5.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01062</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸80C/D</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details6.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01063</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸85A/B</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details7.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01064</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸85C/D</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details8.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择远红外塑体衣产品尺码" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01051</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)M</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details9.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01052</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)L</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details10.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01053</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XL</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details11.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01054</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XXl</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details12.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01055</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XXXL</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details13.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            康丽舒套组-->
            <div v-show="opens">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择远健康磁性护腰产品尺码" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-02</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(M)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details14.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-03</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(L)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details15.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-04</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(XL)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details16.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01043</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(XXL)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details17.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            睡眠套组-->
            <div v-show="open1">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(购买睡眠凝胶套组，请选择对应床垫尺码)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="18"><div class="grid-content bg-purple">健康磁性多功能床垫1张(1.8米)、负离子远红被一张、健康磁性枕2个</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details24.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="18"><div class="grid-content bg-purple">健康磁性多功能床垫1张(1.5米)、负离子远红被一张、健康磁性枕2个</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details25.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            纳米微雕套组-->
            <div v-show="open2">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(购买0508纳米微雕3.0套组，请选择对应尺码)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01024</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">魅力红围巾</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details26.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T015-01-30</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">天空蓝围巾</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details27.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T015-01-40</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">高级灰围巾</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details28.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            家庭理疗金套组A-->
            <div v-show="open3">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(购买家庭理疗金套餐A，请选择对应套餐)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio3" label="1">A套餐：赠送A积分</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio3" label="1">积分</el-radio>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio3" label="2">B套餐：便携舒络仪1台，按摩霜10支，醋蛋液300Ml装2箱</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio3" label="2">产品</el-radio>
                </el-row>
              </el-tabs>
            </div>
<!--            家庭理疗金套组B-->
            <div v-show="open4">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(购买家庭理疗金卡套餐B，请选择对应套餐)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio4" label="1">A套餐：赠送A积分</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio4" label="1">积分</el-radio>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio4" label="2">B套餐：便携舒络仪1台，按摩霜10支，醋蛋液300Ml装2箱</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio4" label="2">产品</el-radio>
                </el-row>
              </el-tabs>
            </div>
<!--            糖宜康套组-->
            <div v-show="open5">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择糖宜康套组" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01035</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康男内裤2.0(M)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details33.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01036</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康男内裤2.0(L)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details34.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01037</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康男内裤2.0(XL)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details35.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01038</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康男内裤2.0(XXL)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details36.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01039</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康男内裤2.0(XXXL)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details37.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            护膝套组-->
            <div v-show="open6">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择护膝套组" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T001-01-02</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康护膝(M)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details38.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T001-01-03</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康护膝(L)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details39.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="12"><div class="grid-content bg-purple">CN-T001-01-04</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple">健康护膝(XL)</div></el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details40.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            便携舒络仪-->
            <div v-show="open7">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(购买便携舒络仪3.0，请选择对应套餐)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio4" label="1">A套餐：赠送A积分</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio5" label="1">积分</el-radio>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio4" label="2">B套餐：便携舒络仪1台，按摩霜10支，醋蛋液300Ml装2箱</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio5" label="2">产品</el-radio>
                </el-row>
              </el-tabs>
            </div>
<!--            满200加赠-->
            <div v-show="open8">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(每满￥200加购套餐)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="8"><div class="grid-content bg-purple">加购金额 ￥58 (消费指数0)</div></el-col>
                  <el-col :span="10"><div class="grid-content bg-purple">富勒烯水润面膜1盒+雪活泉焕颜紧致喷雾</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details43.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="8"><div class="grid-content bg-purple">加购金额 ￥108 (消费指数0)</div></el-col>
                  <el-col :span="10"><div class="grid-content bg-purple">活性肽焕颜多效面膜1盒+雪活泉焕颜紧致喷雾</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details44.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="8"><div class="grid-content bg-purple">加购金额 ￥148 (消费指数0)</div></el-col>
                  <el-col :span="10"><div class="grid-content bg-purple">水光紧致面膜（新）1盒+雪活泉焕颜紧致喷雾</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details45.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="8"><div class="grid-content bg-purple">加购金额 ￥148 (消费指数0)</div></el-col>
                  <el-col :span="10"><div class="grid-content bg-purple">水光净妍面膜（新）+雪活泉焕颜紧致喷雾</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details46.quantity"></el-input></div></el-col>
                </el-row>
                <p>可换购次数为<span style="color: red">{{this.total8}}</span>次</p>
              </el-tabs>
            </div>
<!--            时光宝盒B-->
            <div v-show="open12">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(购买	时光宝盒B，请选择对应套餐)" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio4" label="1">A套餐：赠送A积分</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio12" label="1">积分</el-radio>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px;margin-left: 20px">
                  <!--                <el-radio style="margin: 10px 0px" v-model="radio4" label="2">B套餐：便携舒络仪1台，按摩霜10支，醋蛋液300Ml装2箱</el-radio>-->
                  <el-radio style="margin: 10px 0px" v-model="radio12" label="2">产品</el-radio>
                </el-row>
              </el-tabs>
            </div>
            <div v-show="open9">
              <p style="color:red;font-size: 18px">已满足订单每满￥200，可获得一次加购活动，如若需要请在上方选择套餐，如不需要请点击下一步</p>
            </div>
<!--            时间已过-->
            <div v-show="open10">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择健康护膝尺码" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">CN-T001-01-02</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康护膝 1盒(M)</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details47.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">CN-T001-01-03</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康护膝 1盒(L)</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details48.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">CN-T001-01-04</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康护膝 1盒(XL)</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details49.quantity"></el-input></div></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            时间已过-->
            <div v-show="open11">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择健康护膝尺码" name="first" />
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">CN-T003-01-02</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康磁性护腰2.0 1个(M)+钙铁锌硒胶囊 1盒</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details50.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">CN-T003-01-03</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康磁性护腰2.0 1个(L)+钙铁锌硒胶囊 1盒</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details51.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">CN-T003-01-04</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康磁性护腰2.0 1个(XL)+钙铁锌硒胶囊 1盒</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details52.quantity"></el-input></div></el-col>
                </el-row>
                <el-row :gutter="20" style="margin-bottom: 10px">
                  <el-col :span="6"><div class="grid-content bg-purple">QC(CN)01043</div></el-col>
                  <el-col :span="12"><div class="grid-content bg-purple">健康磁性护腰2.0 1个(XXL)+钙铁锌硒胶囊 1盒</div></el-col>
                  <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details53.quantity"></el-input></div></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            满300加赠   时间已过-->
            <div v-show="open13">
              <p style="color:red;font-size: 18px">剩余可加购数量：{{this.surpluspv}}</p>
              <p style="color:red;font-size: 18px">已满足订单每满300加赠活动，如不需要请点击下一步</p>
<!--              <p style="color:red;font-size: 16px">注：加湿型空气净化器只能加购一台</p>-->
<!--              <p>可加购数量为{{Exchangenum}}</p>-->
<!--              <el-row :gutter="20" style="margin-bottom: 10px">-->
<!--                <el-col :span="6"><div class="grid-content bg-purple">BJ(CN)02013</div></el-col>-->
<!--                <el-col :span="12"><div class="grid-content bg-purple">空气净化凝胶（单瓶装）</div></el-col>-->
<!--                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details57.quantity"></el-input></div></el-col>-->
<!--              </el-row>-->
              <el-table
                  :key="tableKey1"
                  :data="list"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
              >
                <el-table-column label="商品编码" prop="code" width="140" align="center" />
                <el-table-column label="商品名称" prop="name" show-overflow-tooltip header-align="center" />
                <el-table-column label="价格" prop="price" width="80" align="center"/>
                <el-table-column label="消费指数" prop="pv" width="80" align="center"/>
                <el-table-column label="购买数量" prop="quantity" width="120" align="center">
                    <template slot-scope="{row}">
                      <el-input-number
                          v-model="row.quantity"
                          size="mini"
                          :min="0"
                          @change="handleQuantityChange(row)"
                          style="width:100px;"
                      />
                    </template>
                </el-table-column>
              </el-table>

<!--              <el-row :gutter="20" style="margin-bottom: 10px">-->
<!--                <el-col :span="6"><div class="grid-content bg-purple">BJ(CN)02013</div></el-col>-->
<!--                <el-col :span="12"><div class="grid-content bg-purple">空气净化凝胶（单瓶装）</div></el-col>-->
<!--                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details57.quantity"></el-input></div></el-col>-->
<!--              </el-row>-->
            </div>
            <div v-show="list1open">
              <p style="color:red;font-size: 18px">3.8促销</p>
<!--              <p style="color:red;font-size: 18px">可加购数量{{this.list1num}}</p>-->
              <el-table
                  :key="tableKey"
                  :data="list1"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
              >
                <el-table-column label="商品编码" prop="code" width="140" align="center" />
                <el-table-column label="商品名称" prop="name" show-overflow-tooltip header-align="center" />
                <el-table-column label="价格" prop="price" width="80" align="center"/>
                <el-table-column label="消费指数" prop="pv" width="80" align="center"/>
                <el-table-column label="购买数量" prop="quantity" width="120" align="center">
                  <template slot-scope="{row}">
                    <el-input-number
                        v-model="row.quantity"
                        size="mini"
                        :min="0"
                        @change="handleQuantityChange(row)"
                        style="width:100px;"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-show="FXCN02008open">
              <p style="color: red">购买产品FX(CN)02008可享受买一赠一赠实物活动</p>
              <el-table
                  :key="tableKey"
                  :data="list2"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
              >
                <el-table-column label="商品编码" prop="code" width="140" align="center" />
                <el-table-column label="商品名称" prop="name" show-overflow-tooltip header-align="center" />
                <el-table-column label="价格" prop="price" width="80" align="center"/>
                <el-table-column label="消费指数" prop="pv" width="80" align="center"/>
                <el-table-column label="购买数量" prop="quantity" width="120" align="center">
                  <template slot-scope="{row}">
                    <el-input-number
                        v-model="row.quantity"
                        size="mini"
                        :min="0"
                        @change="handleQuantityChange(row)"
                        style="width:100px;"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-show="FXCN02009open">
              <p style="color: red">购买产品FX(CN)02009可享受买一赠一赠实物活动</p>
              <el-table
                  :key="tableKey"
                  :data="list3"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
              >
                <el-table-column label="商品编码" prop="code" width="140" align="center" />
                <el-table-column label="商品名称" prop="name" show-overflow-tooltip header-align="center" />
                <el-table-column label="价格" prop="price" width="80" align="center"/>
                <el-table-column label="消费指数" prop="pv" width="80" align="center"/>
                <el-table-column label="购买数量" prop="quantity" width="120" align="center">
                  <template slot-scope="{row}">
                    <el-input-number
                        v-model="row.quantity"
                        size="mini"
                        :min="0"
                        @change="handleQuantityChange(row)"
                        style="width:100px;"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
<!--        TZCN19267    9.8 14:00 -  9.9 23:59:59 -->
            <!--<div v-show="TZCN19267" style="padding: 20px">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(促销期间购买购买0908健康磁性枕护膝套组（TZCN19267），请选择套餐)" name="first" />
                <el-row>
                  <el-col :span="8">CN-T001-01-04</el-col>
                  <el-col :span="12">健康护膝(XL)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details62.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T001-01-02</el-col>
                  <el-col :span="12">健康护膝(M)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details63.quantity" size="mini" :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T001-01-03</el-col>
                  <el-col :span="12">健康护膝(L)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details64.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(促销期间购买购买0908健康磁性枕护膝套组（TZCN19267），请选择套餐)" name="first" />
                <el-row>
                  <el-col :span="8">CN-T015-01-40</el-col>
                  <el-col :span="12">能量围巾（高级灰）</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details65.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T015-01-30</el-col>
                  <el-col :span="12">能量围巾（天空蓝）</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details66.quantity" size="mini" :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01024</el-col>
                  <el-col :span="12">能量围巾（魅力红）</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details67.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>-->
<!--            TZ(CN)19273   1008健康男内裤套组   时间 10.8 20:00 - 24:00 -->
            <div v-show="TZCN19273" style="padding: 20px">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(促销期间购买购买1008健康男内裤套组（TZ(CN)19273），请选择正品尺码)" name="first" />
                <el-row>
                  <el-col :span="8">QC(CN)01035</el-col>
                  <el-col :span="12">健康男内裤2.0(M) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details68.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01036</el-col>
                  <el-col :span="12">健康男内裤2.0(L) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details69.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01037</el-col>
                  <el-col :span="12">健康男内裤2.0(XL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details70.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01038</el-col>
                  <el-col :span="12">健康男内裤2.0(XXl) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details71.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01039</el-col>
                  <el-col :span="12">健康男内裤2.0(XXXL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details72.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(促销期间购买购买1008健康男内裤套组（TZ(CN)19273），请选择赠品尺码)" name="first" />
                <el-row>
                  <el-col :span="8">QC(CN)01044</el-col>
                  <el-col :span="12">健康女内裤2.0(S) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details73.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01045</el-col>
                  <el-col :span="12">健康女内裤2.0(M) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details74.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01046</el-col>
                  <el-col :span="12">健康女内裤2.0(L) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details75.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01047</el-col>
                  <el-col :span="12">健康女内裤2.0(XL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details76.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01048</el-col>
                  <el-col :span="12">健康女内裤2.0(XXL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details77.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--           TZ(CN)19280  1108健康男内裤男袜套组  时间 11.8 20:00 - 11.8 23:59:59 -->
            <div v-show="TZCN19280" style="padding: 20px">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="(促销期间购买购买1108健康男内裤套组（TZ(CN)19280），请选择正品尺码)" name="first" />
                <el-row>
                  <el-col :span="8">QC(CN)01035</el-col>
                  <el-col :span="12">健康男内裤2.0(M) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details78.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01036</el-col>
                  <el-col :span="12">健康男内裤2.0(L) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details79.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01037</el-col>
                  <el-col :span="12">健康男内裤2.0(XL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details80.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01038</el-col>
                  <el-col :span="12">健康男内裤2.0(XXl) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details81.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01039</el-col>
                  <el-col :span="12">健康男内裤2.0(XXXL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details82.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--           60加赠 时间 11.3 - 11.30 -->
            <div v-show="HZCN01055">
              <p style="color:red;font-size: 18px">折扣订货“臻颜凝萃旅行套装”，在享受买一赠一的同时，可加60元钱再获得2支臻颜凝萃恒时眼霜</p>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="8">CN-C015-01-00</el-col>
                <el-col :span="12">臻颜凝萃恒时眼霜（2支）</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details83.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </div>
<!--            能量围巾 1条【QC(CN)01024/CN-T015-01-40/CN-T015-01-30】  12.8 20:00-23:59:59-->
            <div v-show="QCCN010" style="padding: 20px">
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择健康磁性护腰尺码" name="first" />
                <el-row>
                  <el-col :span="8">CN-T003-01-02</el-col>
                  <el-col :span="12">健康磁性护腰2.0(M) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details84.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T003-01-03</el-col>
                  <el-col :span="12">健康磁性护腰2.0(L) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details85.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T003-01-04</el-col>
                  <el-col :span="12">健康磁性护腰2.0(XL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details86.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">QC(CN)01043</el-col>
                  <el-col :span="12">健康磁性护腰2.0(XXl) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details87.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择健康护膝尺码" name="first" />
                <el-row>
                  <el-col :span="8">CN-T001-01-02</el-col>
                  <el-col :span="12">健康护膝(M) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details88.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T001-01-03</el-col>
                  <el-col :span="12">健康护膝(L) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details89.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T001-01-04</el-col>
                  <el-col :span="12">健康护膝(XL) (一盒)</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details90.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
              <el-tabs v-model="activeName" >
                <el-tab-pane label="请选择能量围巾尺码" name="first" />
                <el-row>
                  <el-col :span="8">QC(CN)01024</el-col>
                  <el-col :span="12">能量围巾（魅力红）</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details91.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T015-01-30</el-col>
                  <el-col :span="12">能量围巾（天空蓝）</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details92.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">CN-T015-01-40</el-col>
                  <el-col :span="12">能量围巾（高级灰）</el-col>
                  <el-col :span="2"><template>
                    <el-input-number v-model="details93.quantity" size="mini"  :min="0"  ></el-input-number>
                  </template></el-col>
                </el-row>
              </el-tabs>
            </div>
<!--            妇女节加购-->
            <div v-show="QCCN01093">
              <p style="color:red;font-size: 18px">折扣订货“新版健康女内裤”或“美妍套组”，另加100元，可享受买一送一实物同时另赠送“温艾套装一盒+新版卫生巾套装1套”</p>
              <p style="color:red;font-size: 18px">根据当前选择产品数量，最多可购买{{this.num99}}套</p>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="8">CN-T007-01-00 CN-D001-01-00</el-col>
                <el-col :span="12">温艾套装1盒 + 新版卫生巾套装1套</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details94.quantity" size="mini"  :min="0"  ></el-input-number>
                </template></el-col>
              </el-row>
            </div>
<!--            满赠-->
            <div v-if="divfullgift">
              <p style="color: red">{{}}</p>
              <el-table
                  :key="tableKey1"
                  :data="fullgift"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
              >
                <el-table-column label="商品编码" prop="code" width="140" align="center" />
                <el-table-column label="商品名称" prop="name" show-overflow-tooltip header-align="center" />
                <el-table-column label="价格" prop="price" width="80" align="center"/>
                <el-table-column label="消费指数" prop="pv" width="80" align="center"/>
                <el-table-column label="购买数量" prop="quantity" width="120" align="center">
                  <template slot-scope="{row}">
                    <el-input-number
                        v-model="row.quantity"
                        size="mini"
                        :min="0"
                        @change="handleQuantityChange(row)"
                        style="width:100px;"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="details95open === true">
              <p v-if="this.Antibakteriterfi === true" style="color: red;font-size: 16px">购买抑菌洗液，可享受赠积分或实物</p>
              <p  v-if="this.Antibakteriterfi === false" style="color: red;font-size: 16px">购买抑菌洗液，716促销期间赠送实物</p>
              <el-radio v-if="this.Antibakteriterfi === true" v-model="integralrealobject1" label="0">积分</el-radio>
              <br>
              <el-radio  style="margin-top: 20px" v-model="integralrealobject1" label="1">实物</el-radio>
            </div>
            <div v-if="details96open === true">
              <p  v-if="this.Antibakteriterfi === true" style="color: red;font-size: 16px">购买抑菌凝胶，可享受赠积分或实物</p>
              <p  v-if="this.Antibakteriterfi === false" style="color: red;font-size: 16px">购买抑菌凝胶，716促销期间赠送实物</p>
              <el-radio v-if="this.Antibakteriterfi === true" v-model="integralrealobject2" label="0">积分</el-radio>
              <br>
              <el-radio style="margin-top: 20px" v-model="integralrealobject2" label="1">实物</el-radio>
            </div>
            <!--折扣订货(6.23--) 买CN-F059-01-00加150元送CN-F026-01-00一个-->
            <div v-if="promotion240623()">
              <p style="color:red;font-size: 18px">折扣订货 买CN-F059-01-00一个加150元送CN-F026-01-00一个,可重复</p>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="8">CN-F026-01-00</el-col>
                <el-col :span="6">糖宜康复合谷粉</el-col>
                <el-col :span="6">最多买{{getPromotion240623Max()}}次</el-col>
                <el-col :span="2"><template>
                  <el-input-number v-model="details97.quantity" size="mini"  :min="0" :max="getPromotion240623Max()" ></el-input-number>
                </template></el-col>
              </el-row>
            </div>
<!--            CN-N000098-24-->
            <CNN00009824 v-if="CNN00009824_num>0&&CNN00009824_time===true" :CNN00009824_num="CNN00009824_num" @CN_N000098_24_Change="CN_N000098_24_Change"></CNN00009824>
            <el-form-item class="btns">
              <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onConfirm">下一步</el-button>
              <el-button v-if="isAgent" icon="el-icon-back" @click="onForward">上一步</el-button>
              <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
            </el-form-item>
          </template>
        </div>
        <div class="step3" v-if="activeIndex===2">
          <div class="pane-title">支付信息</div>
          <el-divider></el-divider>
          <!--          <el-form-item label="积分或实物:" style="margin-bottom:0;"><span><span style="color: red">{{ order.isH0orgood === '0' ? '积分' : (order.isH0orgood === '1' ? '实物' : '') }}</span></span></el-form-item>-->
          <el-form-item label="商品总金额:" style="margin-bottom:0;"><span>{{ amount }}</span></el-form-item>
          <el-form-item label="应支付金额:" style="margin-bottom:0;"><span class="amt">{{ amount }}</span></el-form-item>
          <el-form-item label="支付密码:" prop="password">
            <el-input v-model.trim="order.password" show-password placeholder="请填写您的支付密码" style="width:280px;"/>
          </el-form-item>
          <div class="pane-title">订单明细</div>
          <el-table
              :data="order.details"
              show-summary
              border
              fit
              stripe
              :summary-method="orderDetailSummaries"
              style="width:100%;margin:10px 0 20px 0;"
          >
            <el-table-column label="商品编码" prop="code" width="140" align="center" />
            <el-table-column label="商品名称" prop="name" header-align="center" show-overflow-tooltip />
            <el-table-column label="商品价格" prop="price" width="80" align="center"/>
            <el-table-column label="消费指数" prop="pv" width="80" align="center" />
            <el-table-column label="购买数量" prop="quantity" width="80" align="center"/>
            <el-table-column label="金额合计" width="100" align="center">
              <template slot-scope="{row}">
                <span>{{ (row.price * row.quantity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="消费指数合计" width="110" align="center">
              <template slot-scope="{row}">
                <span>{{ (row.pv * row.quantity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否赠品" width="80" align="center">
              <template slot-scope="{row}">
                <div v-if="row.gift === '是' ">是</div>
                <div v-else>否</div>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item class="btns">
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onSubmit">保存</el-button>
            <el-button icon="el-icon-back" @click="onForward2">上一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step4" v-if="activeIndex===3">
          <div class="success">
            <img :src="require('@/assets/images/success.png')">
            <div class="success-txt">恭喜您，创建订单成功。</div>
            <div class="success-txt">订单号：<span>{{ orderNo }}</span></div>
            <div>
              <el-button icon="el-icon-s-home" @click="onBackHome">查看订单</el-button>
              <el-button icon="el-icon-refresh" @click="onContinue">继续订货</el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductSelector from '@/components/ProductSelector'
import {validateMember, fetchGoods, createOrder} from '@/api/order/re-consume'
import { fetchCustomerType } from '@/api/member/profile'
import { searchMember } from '@/api/agent'
import {checkNo} from "@/api/order/re-consume"
import CNN00009824 from "@/components/promotion/CN-N000098-24.vue";

export default {
  name: 'OrderReConsume',
  components: { ProductSelector ,CNN00009824},
  data() {
    return {
      // CN-N000098-24
      CNN00009824_num:0,
      cnn00009824_num:0,
      CNN00009824_time:false,
      CNN00009824_list:0,
      //
      fullgift:[],
      Antibakteriterfi:true,
      fullgifttime:false,
      fullgiftnum:0,
      divfullgift:false,
      tableKey:0,
      tableKey1:0,
      listopen:false,
      surpluspv:0,
      list:[
        {
          name:'新版健康女内裤(S)',
          code:'QC(CN)01093',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-116
        },
        {
          name:'新版健康女内裤(M)',
          code:'QC(CN)01094',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-117
        },
        {
          name:'新版健康女内裤(L)',
          code:'QC(CN)01095',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-118
        },
        {
          name:'新版健康女内裤(XL)',
          code:'QC(CN)01096',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-119
        },
        {
          name:'新版健康女内裤(XXL)',
          code:'QC(CN)01097',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-120
        },
      ],
      list1:[
        {
          name:'新版健康女内裤(S)',
          code:'QC(CN)01093',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-116
        },
        {
          name:'新版健康女内裤(M)',
          code:'QC(CN)01094',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-117
        },
        {
          name:'新版健康女内裤(L)',
          code:'QC(CN)01095',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-118
        },
        {
          name:'新版健康女内裤(XL)',
          code:'QC(CN)01096',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-119
        },
        {
          name:'新版健康女内裤(XXL)',
          code:'QC(CN)01097',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-120
        },
      ],
      FXCN02008open:false,
      FXCN02008num:0,
      FXCN02009num:0,
      FXCN02009open:false,
      list2:[
        {
          name:'和治友德领带2.0(红色)',
          code:'FX(CN)02008',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-121
        },
        {
          name:'和治友德领带2.0(蓝色)',
          code:'FX(CN)02009',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-122
        },
      ],
      list4:[],
      list3:[
        {
          name:'和治友德领带2.0(红色)',
          code:'FX(CN)02008',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-121
        },
        {
          name:'和治友德领带2.0(蓝色)',
          code:'FX(CN)02009',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-122
        },
      ],
      checkNo:false,
      list1open:false,
      list1num:0,
      list1nums:0,
      //满300加赠
      open13:true,
      open13num:0,
      amountofmoney:null,
      Exchangenum:null,
      memberNo: '',
      activeIndex: 0,
      loading: false,
      isCustomer: undefined,
      order: {
        memberNo: undefined,
        password: undefined,
        details: []
      },
      rules: {
        memberNo: [{ required: true, message: '请填写订货人编号', trigger: 'change' }],
        password: [{ required: true, message: '请填写您的支付密码', trigger: 'change' }]
      },
      goods: [],
      goodsLoading: false,
      orderNo: undefined,
      ids:0,
      ids1:0,
      activeName:'first',
      open:false,
      opens:false,
      //1-13 TZ(CN)19012  女神套装
      details1:{
        name:'旋磁智感健康文胸70A/B',
        code:'QC(CN)01057',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-1
      },
      details2:{
        name:'旋磁智感健康文胸70C/D',
        code:'QC(CN)01058',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-2
      },
      details3:{
        name:'旋磁智感健康文胸75A/B',
        code:'QC(CN)01059',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-3
      },
      details4:{
        name:'旋磁智感健康文胸75C/D',
        code:'QC(CN)01060',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-4
      },
      details5:{
        name:'旋磁智感健康文胸80A/B',
        code:'QC(CN)01061',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-5
      },
      details6:{
        name:'旋磁智感健康文胸80C/D',
        code:'QC(CN)01062',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-6
      },
      details7:{
        name:'旋磁智感健康文胸85A/B',
        code:'QC(CN)01063',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-7
      },
      details8:{
        name:'旋磁智感健康文胸85C/D',
        code:'QC(CN)01064',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-8
      },
      details9:{
        name:'远红外塑体衣(平角款)M',
        code:'QC(CN)01051',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-9
      },
      details10:{
        name:'远红外塑体衣(平角款)L',
        code:'QC(CN)01052',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-10
      },
      details11:{
        name:'远红外塑体衣(平角款)XL',
        code:'QC(CN)01053',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-11
      },
      details12:{
        name:'远红外塑体衣(平角款)XXL',
        code:'QC(CN)01054',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-12
      },
      details13:{
        name:'远红外塑体衣(平角款)XXXL',
        code:'QC(CN)01055',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-13
      },
      //康丽舒套组  14-17
      details14:{
        name:'健康磁性护腰2.0(M)',
        code:'CN-T003-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-14
      },
      details15:{
        name:'健康磁性护腰2.0(L)',
        code:'CN-T003-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-15
      },
      details16:{
        name:'健康磁性护腰2.0(XL)',
        code:'CN-T003-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-16
      },
      details17:{
        name:'健康磁性护腰2.0(XXL)',
        code:'QC(CN)01043',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-17
      },
      //睡眠套组
      open1:false,
      details24:{
        name:'健康磁性多功能床垫1张(1.8米)、负离子远红被一张、健康磁性枕2个',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-21
      },
      details25:{
        name:'健康磁性多功能床垫1张(QC(1.5米)、负离子远红被一张、健康磁性枕2个',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-22
      },
      //0508纳米微雕3.0套组
      nowTime:'',
      open2:false,
      timer:'',
      details26:{
        name:'魅力红围巾',
        code:'QC(CN)01024',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-23
      },
      details27:{
        name:'天空蓝围巾',
        code:'CN-T015-01-30',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-24
      },
      details28:{
        name:'高级灰围巾',
        code:'CN-T015-01-40',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-25
      },
      //家庭理疗套餐A
      open3:false,
      radio3:'0',
      details29:{
        // name:'A套餐：赠送A积分',
        name:'积分',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-26
      },
      details30:{
        // name:'B套餐：便携舒络仪1台，按摩霜10支，醋蛋液300Ml装2箱',
        name:'产品',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-27
      },
      //家庭理疗套餐B
      open4:false,
      radio4:'0',
      details31:{
        // name:'A套餐：赠送A积分',
        name:'积分',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-28
      },
      details32:{
        // name:'B套餐：便携舒络仪1台，按摩霜10支，和治友德口服液17盒',
        name:'产品',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-29
      },
      //  0608糖宜康套组
      open5:false,
      details33:{
        name:'健康男内裤2.0(M)',
        code:'QC(CN)01035',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-33
      },
      details34:{
        name:'健康男内裤2.0(L)',
        code:'QC(CN)01036',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-34
      },
      details35:{
        name:'健康男内裤2.0(XL)',
        code:'QC(CN)01037',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-35
      },
      details36:{
        name:'健康男内裤2.0(XXL)',
        code:'QC(CN)01038',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-36
      },
      details37:{
        name:'健康男内裤2.0(XXXL)',
        code:'QC(CN)01039',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-37
      },
      //  0608护膝套组
      open6:false,
      details38:{
        name:'健康护膝(M)',
        code:'CN-T001-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-38
      },
      details39:{
        name:'健康护膝(L)',
        code:'CN-T001-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-39
      },
      details40:{
        name:'健康护膝(XL)',
        code:'CN-T001-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-40
      },
      abcd: '',
      radio5:'0',
      open7:false,
      giveStatus:0,
      details41:{
        // name:'A套餐：赠送A积分',
        name:'积分',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        giveStatus:2,
        gift:'是',
        id:-41
      },
      details42:{
        // name:'B套餐：便携舒络仪1台，按摩霜10支，和治友德口服液17盒',
        name:'产品',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        giveStatus:1,
        gift:'是',
        id:-42
      },
      //满200加购促销
      open8: false,
      open9: false,
      total1:0,
      total2:0,
      total3:0,
      total4:0,
      total5:0,
      total6:0,
      total7:null,
      total8: 0,
      details43:{
        name:'富勒烯水润面膜1盒+雪活泉焕颜紧致喷雾',
        code:'',
        quantity: 0,
        point:0,
        price:58,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-43
      },
      details44:{
        name:'活性肽焕颜多效面膜1盒+雪活泉焕颜紧致喷雾',
        code:'',
        quantity: 0,
        point:0,
        price:108,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-44
      },
      details45:{
        name:'水光紧致面膜（新）1盒+雪活泉焕颜紧致喷雾',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-45
      },
      details46:{
        name:'水光净妍面膜（新）+雪活泉焕颜紧致喷雾',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-46
      },
      open10:false,
      details47:{
        name:'健康护膝(X)',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-47
      },
      details48:{
        name:'健康护膝(L)',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-48
      },
      details49:{
        name:'健康护膝(XL)',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-49
      },
      open11:false,
      details50:{
        name:'健康磁性护腰2.0 1个(M)+钙铁锌硒胶囊 1盒',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-50
      },
      details51:{
        name:'健康磁性护腰2.0 1个(L)+钙铁锌硒胶囊 1盒',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-51
      },
      details52:{
        name:'健康磁性护腰2.0 1个(XL)+钙铁锌硒胶囊 1盒',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-52
      },
      details53:{
        name:'健康磁性护腰2.0 1个(XXL)+钙铁锌硒胶囊 1盒',
        code:'',
        quantity: 0,
        point:0,
        price:148,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-53
      },
    //  11693 时光宝盒B
      open12:false,
      radio12:'0',
      details54:{
        name:'积分',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-54
      },
      details55:{
        name:'产品',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-55
      },
      details56:{
        name:'和治友德口服液(一盒)',
        code:'CN-F004-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-56
      },
      details57:{
        name:'空气净化凝胶（单瓶装）',
        code:'BJ(CN)02013',
        quantity: 0,
        point:0,
        price:50,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-57
      },
      TZCN19267: false,
      details62:{
        name:'健康护膝(XL)',
        code:'CN-T001-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-62
      },
      details63:{
        name:'健康护膝(M)',
        code:'CN-T001-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-63
      },
      details64:{
        name:'健康护膝(L)',
        code:'CN-T001-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-64
      },
      details65:{
        name:'能量围巾（高级灰）',
        code:'CN-T015-01-40',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-65
      },
      details66:{
        name:'能量围巾（天空蓝）',
        code:'CN-T015-01-30',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-66
      },
      TZCN19273:false,
      details67:{
        name:'能量围巾（魅力红）',
        code:'QC(CN)01024',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-67
      },
      details68:{
        name:'健康男内裤2.0(M) (一盒)',
        code:'QC(CN)01035',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'否',
        id:-68
      },
      details69:{
        name:'健康男内裤2.0(L) (一盒)',
        code:'QC(CN)01036',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'否',
        id:-69
      },
      details70:{
        name:'健康男内裤2.0(XL) (一盒)',
        code:'QC(CN)01037',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'否',
        id:-70
      },
      details71:{
        name:'健康男内裤2.0(XXL) (一盒)',
        code:'QC(CN)01038',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'否',
        id:-71
      },
      details72:{
        name:'健康男内裤2.0(XXXL) (一盒)',
        code:'QC(CN)01039',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'否',
        id:-72
      },
      details73:{
        name:'健康女内裤2.0(S) (一盒)',
        code:'QC(CN)01044',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-73
      },
      details74:{
        name:'健康女内裤2.0(M) (一盒)',
        code:'QC(CN)01045',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-74
      },
      details75:{
        name:'健康女内裤2.0(L) (一盒)',
        code:'QC(CN)01046',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-75
      },
      details76:{
        name:'健康女内裤2.0(XL) (一盒)',
        code:'QC(CN)01047',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-76
      },
      details77:{
        name:'健康女内裤2.0(XXL) (一盒)',
        code:'QC(CN)01048',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-77
      },
      TZCN19280:false,
      details78:{
        name:'健康男内裤2.0(M) (一盒)',
        code:'QC(CN)01035',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-78
      },
      details79:{
        name:'健康男内裤2.0(L) (一盒)',
        code:'QC(CN)01036',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-79
      },  details80:{
        name:'健康男内裤2.0(XL) (一盒)',
        code:'QC(CN)01037',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-80
      },
      details81:{
        name:'健康男内裤2.0(XXL) (一盒)',
        code:'QC(CN)01038',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-81
      },
      details82:{
        name:'健康女内裤2.0(XXXL) (一盒)',
        code:'QC(CN)01039',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-82
      },
      HZCN01055:false,
      details83:{
        name:'臻颜凝萃恒时眼霜 (两支)',
        code:'QC(CN)01039',
        quantity: 0,
        point:0,
        price:60,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-83
      },
      QCCN010:false,
      details84:{
        name:'健康磁性护腰 (M) (一盒)',
        code:'CN-T003-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-84
      },
      details85:{
        name:'健康磁性护腰(L) (一盒)',
        code:'CN-T003-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-85
      },
      details86:{
        name:'健康磁性护腰(XL) (一盒)',
        code:'CN-T003-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-86
      },
      details87:{
        name:'健康磁性护腰(XXL) (一盒)',
        code:'QC(CN)01043',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-87
      },
      details88:{
        name:'健康护膝(M) (一盒)',
        code:'CN-T001-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-88
      },
      details89:{
        name:'健康护膝(L) (一盒)',
        code:'CN-T001-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-89
      },
      details90:{
        name:'健健康护膝(XL) (一盒)',
        code:'CN-T001-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-90
      },
      details91:{
        name:'能量围巾（魅力红）',
        code:'QC(CN)01024',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-91
      },
      details92:{
        name:'能量围巾（天空蓝）',
        code:'CN-T015-01-30',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-92
      },
      details93:{
        name:'能量围巾（高级灰）',
        code:'CN-T015-01-40',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-93
      },
      QCCN01093:false,
      num93:0,
      num94:0,
      num95:0,
      num96:0,
      num97:0,
      num98:0,
      num99:0,
      details94:{
        name:'温艾套装（1盒） + 新版卫生巾（1套）',
        code:'',
        quantity: 0,
        point:0,
        price:100,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-99
      },
      details95open:false,
      integralrealobject1:'',
      integralrealobject2:'',
      details96open:false,
      details95:{
        name:'',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-995
      },
      details96:{
        name:'',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-996
      },
      details97:{
        name:'糖宜康复合谷粉',
        code:'CN-F026-01-00',
        quantity: 0,
        point:0,
        price:150,
        proPrice:0,
        pv:0,
        gift:'是',
        id:-101
      },
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'isAgent'
    ]),
    amount() {
      if (!this.order.details || !this.order.details.length) {
        return 0
      }
      return this.order.details.reduce((acc, cur) => {
        return cur.price * cur.quantity + acc
      }, 0).toFixed(2)
    },
    pv() {
      if (!this.order.details || !this.order.details.length) {
        return 0
      }
      return this.order.details.reduce((acc, cur) => {
        return cur.pv * cur.quantity + acc
      }, 0).toFixed(2)
    },
    quantity() {
      if (!this.list || !this.list.length) {
        return 0
      }
      return this.list.reduce((acc, cur) => {
        return cur.quantity + acc
      }, 0)
    }
  },
  watch:{
    $route(to,from){
      console.log(11)
      console.log(to,from)
      window.location.reload()
    },
    'order.memberNo':function(val) {
      this.order.memberNo = val.toUpperCase()
    },
    pv:function (val){
      this.amountofmoney = val
    },
    goods:{
      handler() {
        this.Exchangenum = Math.floor(this.amountofmoney/300)
        for (let i = 0; i < this.goods.length; i++) {
          for (let j = 0; j < this.goods[i].children.length; j++) {
            // CN-N000098-24
            if(this.goods[i].children[j].id === 14553 && this.goods[i].children[j].quantity > 0 ){
              this.CNN00009824_num = this.goods[i].children[j].quantity
            }
            if(this.goods[i].children[j].id === 14553 && this.goods[i].children[j].quantity === 0){
              this.CNN00009824_num = 0
            }

            // 2023 7.16促销
            if(this.goods[i].children[j].id === 13953 && this.goods[i].children[j].quantity > 0 ){
              this.FXCN02008open = true
              this.FXCN02008num = this.goods[i].children[j].quantity
            }
            if(this.goods[i].children[j].id === 13953 && this.goods[i].children[j].quantity === 0){
              this.FXCN02008open = false
              this.FXCN02008num = 0
              this.list2[0].quantity = 0
              this.list2[1].quantity = 0
            }
            if(this.goods[i].children[j].id === 13954 && this.goods[i].children[j].quantity > 0 ){
              this.FXCN02009open = true
              this.FXCN02009num = this.goods[i].children[j].quantity
            }
            if(this.goods[i].children[j].id === 13954 && this.goods[i].children[j].quantity === 0){
              this.FXCN02009open = false
              this.FXCN02009num = 0
              this.list3[0].quantity = 0
              this.list3[1].quantity = 0
            }
            //2023 3.8促销
            if(this.goods[i].children[j].id === 13793 && this.goods[i].children[j].quantity > 0 ){
              this.list1open = true
            }
            if(this.goods[i].children[j].id === 13793 && this.goods[i].children[j].quantity === 0){
              this.list1open = false
              this.list1[0].quantity = 0
              this.list1[1].quantity = 0
              this.list1[2].quantity = 0
              this.list1[3].quantity = 0
              this.list1[4].quantity = 0
            }
            //女神套组 1-13
            if (this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '女神套装') {
              this.open = true
            }
            if (this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity === 0 && this.goods[i].children[j].name === '女神套装') {
              this.open = false
              this.details1.quantity = 0
              this.details2.quantity = 0
              this.details3.quantity = 0
              this.details4.quantity = 0
              this.details5.quantity = 0
              this.details6.quantity = 0
              this.details7.quantity = 0
              this.details8.quantity = 0
              this.details9.quantity = 0
              this.details10.quantity = 0
              this.details11.quantity = 0
              this.details12.quantity = 0
              this.details13.quantity = 0
            }
            //康丽舒套组  14-17
            if (this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '康丽舒络套组') {
              this.opens = true
            }
            if (this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity === 0 && this.goods[i].children[j].name === '康丽舒络套组') {
              this.opens = false
              this.details14.quantity = 0
              this.details15.quantity = 0
              this.details16.quantity = 0
              this.details17.quantity = 0
            }
            //养生睡眠套组
            if (this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity > 0) {
              this.open1 = true
            }
            if (this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity === 0) {
              this.open1 = false
              this.details24.quantity = 0
              this.details25.quantity = 0
            }
            //0508纳米微雕3.0套组
            if (this.goods[i].children[j].id === 12856 && this.goods[i].children[j].quantity > 0 && 1620473400000 < this.nowTime < 1628006369000) {
              this.open2 = true
            }
            if (this.goods[i].children[j].quantity === 0 && this.goods[i].children[j].id === 12856 && 1620473400000 < this.nowTime < 1628006369000) {
              this.open2 = false
              this.details26.quantity = 0
              this.details27.quantity = 0
              this.details28.quantity = 0
            }
            //  家庭理疗金套餐A
            if (this.goods[i].children[j].id === 12873 && this.goods[i].children[j].quantity > 0) {
              this.open3 = true
            }
            if (this.goods[i].children[j].id === 12873 && this.goods[i].children[j].quantity === 0) {
              this.open3 = false
              this.radio3 = '0'
            }
            //  家庭理疗金套餐B
            if (this.goods[i].children[j].id === 12874 && this.goods[i].children[j].quantity > 0 && 1622563200000 < this.nowTime && this.nowTime < 1628006369000) {
              this.open4 = true
            }
            if (this.goods[i].children[j].id === 12874 && this.goods[i].children[j].quantity === 0 && 1622563200000 < this.nowTime && this.nowTime < 1628006369000) {
              this.open4 = false
              this.radio4 = '0'
            }
            //0608糖宜康套组
            if (this.goods[i].children[j].id === 12878 && this.goods[i].children[j].quantity > 0 && 1623151800000 < this.nowTime && this.nowTime < 1628006369000) {
              this.open5 = true
            }
            if (this.goods[i].children[j].id === 12878 && this.goods[i].children[j].quantity === 0 && 1623151800000 < this.nowTime && this.nowTime < 1628006369000) {
              this.open5 = false
              this.details33.quantity = 0
              this.details34.quantity = 0
              this.details35.quantity = 0
              this.details36.quantity = 0
              this.details37.quantity = 0
            }
            //  0608护膝套组
            if (this.goods[i].children[j].id === 12879 && this.goods[i].children[j].quantity > 0 && 1623151800000 < this.nowTime && this.nowTime < 1623254399000) {
              this.open6 = true
            }
            if (this.goods[i].children[j].id === 12879 && this.goods[i].children[j].quantity === 0 && 1623151800000 < this.nowTime && this.nowTime < 1623254399000) {
              this.open6 = false
              this.details38.quantity = 0
              this.details39.quantity = 0
              this.details40.quantity = 0
            }
            //  03023  12753
            if (this.goods[i].children[j].id === 14039 && this.goods[i].children[j].quantity > 0) {
              this.open7 = true
            }
            if (this.goods[i].children[j].id === 14039 && this.goods[i].children[j].quantity === 0) {
              this.open7 = false
              this.radio4 = '0'
            }
            //  满200加购促销
            if (this.goods[i].children[j].id === 12457) {
              this.total1 = this.goods[i].children[j].price * this.goods[i].children[j].quantity
            }
            if (this.goods[i].children[j].id === 12458) {
              this.total2 = this.goods[i].children[j].price * this.goods[i].children[j].quantity
            }
            if (this.goods[i].children[j].id === 12459) {
              this.total3 = this.goods[i].children[j].price * this.goods[i].children[j].quantity
            }
            if (this.goods[i].children[j].id === 12460) {
              this.total4 = this.goods[i].children[j].price * this.goods[i].children[j].quantity
            }
            if (this.goods[i].children[j].id === 12461) {
              this.total5 = this.goods[i].children[j].price * this.goods[i].children[j].quantity
            }
            if (this.goods[i].children[j].id === 12462) {
              this.total6 = this.goods[i].children[j].price * this.goods[i].children[j].quantity
            }
            this.total7 = this.total1 + this.total2 + this.total3 + this.total4 + this.total5 + this.total6
            this.total8 = Math.floor(this.total7 / 200)
            // if((this.total7 >=200) && (1623978000000 < this.nowTime)){
            //   this.open8 = true
            //   this.open9 = true
            // }else{
            //   this.open8 = false
            //   this.open9 = false
            //   this.details43.quantity = 0
            //   this.details44.quantity = 0
            //   this.details45.quantity = 0
            //   this.details46.quantity = 0
            // }
            //  19258
            if (this.goods[i].children[j].id === 12914 && this.goods[i].children[j].quantity > 0 && 1625743800000 < this.nowTime && this.nowTime < 1625846399000) {
              this.open10 = true
            }
            if (this.goods[i].children[j].id === 12914 && this.goods[i].children[j].quantity === 0 && 1625743800000 < this.nowTime && this.nowTime < 1625846399000) {
              this.open10 = false
              this.details47.quantity = 0
              this.details48.quantity = 0
              this.details49.quantity = 0
            }
            //  19260
            if (this.goods[i].children[j].id === 12916 && this.goods[i].children[j].quantity > 0 && 1625743800000 < this.nowTime && this.nowTime < 1625846399000) {
              this.open11 = true
            }
            if (this.goods[i].children[j].id === 12916 && this.goods[i].children[j].quantity === 0 && 1625743800000 < this.nowTime && this.nowTime < 1625846399000) {
              this.open11 = false
              this.details50.quantity = 0
              this.details51.quantity = 0
              this.details52.quantity = 0
              this.details53.quantity = 0
            }
            //  11693 时光宝盒B
            if (this.goods[i].children[j].id === 11693 && this.goods[i].children[j].quantity > 0) {
              this.open12 = true
            }
            if (this.goods[i].children[j].id === 11693 && this.goods[i].children[j].quantity === 0) {
              this.open12 = false
              this.radio12 = '0'
            }
            //  满300加购
            if (this.goods[i].children[j].id === 12913 && this.goods[i].children[j].quantity > 0) {
              this.amountofmoney = Number(this.amountofmoney) - ((this.goods[i].children[j].quantity) * (this.goods[i].children[j].pv))
              // console.log(this.amountofmoney,'2')
            }
            if (this.goods[i].children[j].id === 12933 && this.goods[i].children[j].quantity > 0) {
              this.amountofmoney = Number(this.amountofmoney) - ((this.goods[i].children[j].quantity) * (this.goods[i].children[j].pv))
              // console.log(this.amountofmoney,'3')
            }
            if (this.goods[i].children[j].id === 12934 && this.goods[i].children[j].quantity > 0) {
              this.amountofmoney = Number(this.amountofmoney) - ((this.goods[i].children[j].quantity) * (this.goods[i].children[j].pv))
            }
            if (this.goods[i].children[j].id === 12935 && this.goods[i].children[j].quantity > 0) {
              this.amountofmoney = Number(this.amountofmoney) - ((this.goods[i].children[j].quantity) * (this.goods[i].children[j].pv))
            }
          //  TZ（CN)19267 0908健康磁性枕护膝套组
          //  1631080800000 < this.nowTime && this.noeTime < 1631203199000
          //   if(this.goods[i].children[j].id === 13015 && this.goods[i].children[j].quantity > 0 && (1631080800000 < this.nowTime < 1631203199000) ){
          //     this.TZCN19267 = true
          //   }
          //   if(this.goods[i].children[j].id === 13015 && this.goods[i].children[j].quantity === 0 && (1631080800000 < this.nowTime < 1631203199000) ){
          //     this.TZCN19267 = false
          //     this.details62.quantity = 0
          //     this.details63.quantity = 0
          //     this.details64.quantity = 0
          //     this.details65.quantity = 0
          //     this.details66.quantity = 0
          //     this.details67.quantity = 0
          //   }
          //  TZ(CN)19273 1008健康男内裤套组   10.8 20:00 - 24:00
            if(this.goods[i].children[j].id === 13054 && this.goods[i].children[j].quantity > 0 &&  this.nowTime < 1633708799000){
              this.TZCN19273 = true
            }
            if(this.nowTime > 1633708799000){
              this.TZCN19273 = false
            }
            if(this.goods[i].children[j].id === 13054 && this.goods[i].children[j].quantity === 0 && (1632383158000 < this.nowTime < 1633708799000)){
              this.TZCN19273 = false
              this.details68.quantity =0
              this.details69.quantity =0
              this.details70.quantity =0
              this.details71.quantity =0
              this.details72.quantity =0
              this.details73.quantity =0
              this.details74.quantity =0
              this.details75.quantity =0
              this.details76.quantity =0
              this.details77.quantity =0
            }
            // TZ(CN)19280
            if(this.goods[i].children[j].id === 13095 && this.goods[i].children[j].quantity > 0 ){
              this.TZCN19280 = true
            }
            if( (this.nowTime > 1636387199000) || (this.goods[i].children[j].id === 13095 && this.goods[i].children[j].quantity === 0) ){
              this.TZCN19280 = false
              this.details78.quantity =0
              this.details79.quantity =0
              this.details80.quantity =0
              this.details81.quantity =0
              this.details82.quantity =0
            }
          //  妇女节 加购
            if(1646150399000 <this.nowTime && this.nowTime < 1647359999000) {
              if (this.goods[i].children[j].id === 13153 && this.goods[i].children[j].quantity > 0) {
                this.num93 = this.goods[i].children[j].quantity
              }
              if (this.goods[i].children[j].id === 13153 && this.goods[i].children[j].quantity === 0) {
                this.num93 = this.goods[i].children[j].quantity
              }

              if (this.goods[i].children[j].id === 13154 && this.goods[i].children[j].quantity > 0) {
                this.num94 = this.goods[i].children[j].quantity
              }
              if (this.goods[i].children[j].id === 13154 && this.goods[i].children[j].quantity === 0) {
                this.num94 = this.goods[i].children[j].quantity
              }

              if (this.goods[i].children[j].id === 13155 && this.goods[i].children[j].quantity > 0) {
                this.num95 = this.goods[i].children[j].quantity
              }
              if (this.goods[i].children[j].id === 13155 && this.goods[i].children[j].quantity === 0) {
                this.num95 = this.goods[i].children[j].quantity
              }

              if (this.goods[i].children[j].id === 13156 && this.goods[i].children[j].quantity > 0) {
                this.num96 = this.goods[i].children[j].quantity
              }
              if (this.goods[i].children[j].id === 13156 && this.goods[i].children[j].quantity === 0) {
                this.num96 = this.goods[i].children[j].quantity
              }

              if (this.goods[i].children[j].id === 13157 && this.goods[i].children[j].quantity > 0) {
                this.num97 = this.goods[i].children[j].quantity
              }
              if (this.goods[i].children[j].id === 13157 && this.goods[i].children[j].quantity === 0) {
                this.num97 = this.goods[i].children[j].quantity
              }

              if (this.goods[i].children[j].id === 13278 && this.goods[i].children[j].quantity > 0) {
                this.num98 = this.goods[i].children[j].quantity
              }
              if (this.goods[i].children[j].id === 13278 && this.goods[i].children[j].quantity === 0) {
                this.num98 = this.goods[i].children[j].quantity
              }
              this.num99 = this.num93 + this.num94 + this.num95 + this.num96 + this.num97 + (this.num98 * 7)
              if ((this.num93 + this.num94 + this.num95 + this.num96 + this.num97 + this.num98) > 0) {
                this.QCCN01093 = true
              }
              if ((this.num93 + this.num94 + this.num95 + this.num96 + this.num97 + this.num98) === 0) {
                this.QCCN01093 = false
                this.details94.quantity = 0
              }
            }
          //CN-C016-01-00 60加购
          //   if(this.goods[i].children[j].id === 13113 && this.goods[i].children[j].quantity > 0){
          //     this.HZCN01055 = true
          //   }
          //   if(( this.nowTime > 1641311999000) || ( this.goods[i].children[j].id === 13113 && this.goods[i].children[j].quantity === 0)){
          //     this.HZCN01055 = false
          //     this.details83.quantity = 0
          //   }
          //  1208能量围巾套组 12.8 20:00 - 12.8 23:59:59
          //     if(this.goods[i].children[j].id === 13135 && this.goods[i].children[j].quantity > 0 ){
          //       this.QCCN010 = true
          //     }
          //
          //     if( (this.goods[i].children[j].id === 13135 && this.goods[i].children[j].quantity === 0) || this.nowTime === 1638979199000 ){
          //         this.QCCN010 = false
          //         this.details84.quantity = 0
          //         this.details85.quantity = 0
          //         this.details86.quantity = 0
          //         this.details87.quantity = 0
          //         this.details88.quantity = 0
          //         this.details89.quantity = 0
          //         this.details90.quantity = 0
          //         this.details91.quantity = 0
          //         this.details92.quantity = 0
          //         this.details93.quantity = 0
          //     }

          //父亲节促销 13175  满980加400 喜品喜客酒52°2瓶装 1655222399000 - 1655827199000 6.15 - 6.21 && 1655222399000 < this.nowTime
          //   if(this.goods[i].children[j].id === 13175 && this.goods[i].children[j].quantity > 0 && 1655222399000 < this.nowTime && this.nowTime < 1655827199000 && ( this.checkNo === true) ){
          //     this.list1open = true
          //   }
          //   if(this.goods[i].children[j].id === 13175 && this.goods[i].children[j].quantity === 0 && 1655222399000 < this.nowTime && this.nowTime < 1655827199000  ){
          //     this.list1open = false
          //     this.list1[0].quantity = 0
          //   }

            //  13113 2022年8月3日至2022年8月16日 ”折扣订货“ 三盒雪活泉润颜面膜，另加50元，在买一赠一的基础上另赠雪活泉臻颜凝萃旅行套装1套，赠品数量有限，赠完即止。
            if(this.goods[i].children[j].id === 13453 && this.goods[i].children[j].quantity >= 3){
              this.list1nums = this.goods[i].children[j].quantity
              this.list1num = Math.floor(this.goods[i].children[j].quantity/3)
            }

            //this.goods[i].children[j].id === 13453 && this.goods[i].children[j].quantity < 3

           // CN-D012-01-00 CN-D010-01-00
            if(this.goods[i].children[j].id === 13653 && this.goods[i].children[j].quantity > 0 ){
               this.details95open = true
            }
            if(this.goods[i].children[j].id === 13653 && this.goods[i].children[j].quantity === 0 ){
               this.details95open = false
              this.integralrealobject1 = ''
            }
            if(this.goods[i].children[j].id === 13654 && this.goods[i].children[j].quantity > 0 ){
              this.details96open = true
            }if(this.goods[i].children[j].id === 13654 && this.goods[i].children[j].quantity === 0 ){
              this.details96open = false
              this.integralrealobject2 = ''
            }
          }
        }
        if(Number(this.pv) >= 300 && this.checkNo === true ){
          this.open13 = true
          this.surpluspv = Math.floor(Number(this.pv)/300)
        }
        else if(Number(this.pv) >= 300 && this.checkNo === '2'){
          this.open13 = true
          this.list = this.list.splice(0,1)
          this.surpluspv = Math.floor(Number(this.pv)/300)
        }
        else {
          this.open13 = false
            for(let i=0;i<this.list.length;i++){
              this.list[i].quantity = 0
            }
        }
      },
      deep:true,
    },
    // 'order.details': {
    //   deep: true,
    //   handler(val) {
    //     console.log(val, '1111')
    //   }
    // }
  },
  mounted() {
    if(location.href.indexOf("#reloaded")== -1){
      location.href = location.href + '#reloaded'
      location.reload()
    }
    if (!this.isAgent) {
      this.activeIndex = 1
      this.order.memberNo = this.userCode
      fetchCustomerType().then(res => {
        const customerType = res.data
        this.isCustomer = !customerType || customerType === '0'
        if (!this.isCustomer) {
          this.getGoodsData()
        }
      })
      checkNo({'memberNo':this.userCode}).then(res=>{
        if(res.data === '0'){
          this.checkNo = true
          this.open13 = true
        }else if(res.data === '2'){
          this.checkNo = '2'
          this.open13 = true
          this.list = this.list.splice(0,1)
        } else {
          this.checkNo = false
          this.open13 =false
        }
      }).catch(err=>{
        console.error(err)
      })
    }
    this.timer = setInterval(()=>{
      this.nowTime = new Date().getTime()
    },1000)
    this.$once('hook:beforeDestroy', ()=>{
      clearInterval(this.timer)
    })
  },
  created() {
    this.get_CNN00009824_time()
  },
  methods: {
    CN_N000098_24_Change(e){
      this.CNN00009824_list = e
      this.cnn00009824_num = 0
      this.CNN00009824_list.forEach(v=>{
        this.cnn00009824_num += v.quantity
      })
    },
    onBack() {
      this.$router.go(-1)
    },
    onNext() {
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          validateMember({ memberNo: this.order.memberNo }).then(() => {
            this.activeIndex = 1
            this.loading = false
            this.getGoodsData()
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
          checkNo({'memberNo':this.order.memberNo}).then(res=>{
            if(res.data === '0'){
              this.checkNo = true
            }else if(res.data === '2'){
              this.checkNo = '2'
            } else {
              this.checkNo = false
            }
          }).catch(err=>{
            console.error(err)
          })
        }
      })
    },
    getGoodsData() {
      this.goodsLoading = true
      fetchGoods().then(res => {
        this.goods = res.data
        this.goodsLoading = false
      }).catch(err => {
        console.warn(err)
        this.goodsLoading = false
      })
    },
    onForward() {
      this.activeIndex = 0
      this.order.details = []
    },
    productSelectorChange(good) {
      if (good.code==='CN-F059-01-00') {
        if (good.currentValue < this.details97.quantity) {
          this.details97.quantity = good.currentValue
          this.$message.info('请注意: 加赠数量修改为' + good.currentValue)
        }
      }
    },
    getPromotion240623Max() {
      return this.order.details.reduce((sum, item) => {
        if (item.code === 'CN-F059-01-00') {
          return sum + item.quantity
        }
        return sum
      }, 0)
    },
    promotion240623() {
      let date2 = (new Date(2024, 5, 23, 10, 0, 0, 0)).getTime()
      let date3 = (new Date(2024, 6, 10, 0, 0, 0, 0)).getTime()
      return this.order.details.some(item=>item.code==='CN-F059-01-00') && this.nowTime > date2 && this.nowTime < date3
    },
    get_CNN00009824_time(){
      let date1= new Date().getTime()
      let date2 = (new Date(2024, 6, 24, 10, 0, 0, 0)).getTime()
      let date3 = (new Date(2024, 8, 4, 0, 0, 0, 0)).getTime()
      console.log(date1,date2,date3)
      if(date1 >date2 && date1 < date3){
        this.CNN00009824_time = true
      }else{
        this.CNN00009824_time = false
      }
    },
    onConfirm() {
      if(this.CNN00009824_num > 0){
        if(this.cnn00009824_num === this.CNN00009824_num){
          this.CNN00009824_list.forEach(v=>{
            if(v.quantity>0){
              this.order.details.push(v)
            }
          })
        }else{
          return this.$message.error('所选赠品数量与购买套组(CN-N000098-24)数量不符')
        }
      }
      if(this.order.details.some(item=>item.code==='CN-F059-01-00')) {
        if (this.details97.quantity > 0) {
          this.order.details.push(this.details97)
        }
      }
      if(this.details95open === true && this.integralrealobject1 === ''){
        if(this.Antibakteriterfi === true){
          return this.$message.error('请选择赠积分或实物')
        }else{
          return this.$message.error('请选择赠实物')
        }
      }
      if(this.details96open === true && this.integralrealobject2 === ''){
        if(this.Antibakteriterfi === true){
          return this.$message.error('请选择赠积分或实物')
        }else{
          return this.$message.error('请选择赠实物')
        }
      }
      // if (!this.order.isH0orgood){

      //   return this.$message.error('请选择积分或是实物!')
      // }
      if(this.list.length>=2){
        if(this.list[0].quantity + this.list[1].quantity > this.surpluspv){
          return this.$message.error('超出可加购数量')
        }
        if(this.list[1].quantity > 1){
          return this.$message.error('该产品 (QC(CN)02003 加湿型空气净化器) 只能加购一个')
        }
      }else{
        if(this.list[0].quantity > this.surpluspv){
          return this.$message.error('超出可加购数量')
        }
      }
      // if(this.list1[0].quantity+this.list1[1].quantity+this.list1[2].quantity+this.list1[3].quantity+this.list1[4].quantity != 0){
      //   return this.$message.error('请选择产品尺码')
      // } else {
      //   // for(let i=0;i<this.list.length;i++){
      //   //   if(this.list[i].quantity >0){
      //   //     this.order.details.push(this.list[i])
      //   //   }
      //   // }
      //   for(let a=0;a<this.list1.length;a++){
      //     if(this.list1[a].quantity >0){
      //       this.order.details.push(this.list1[a])
      //     }
      //   }
      // }
      for(let z= 0;z<this.list.length;z++){
        if(this.list[z].quantity > 0){
          this.order.details.push(this.list[z])
        }
      }
      if(this.quantity > this.Exchangenum){
        this.listopen = true
        return this.$message.error('超出可加赠数量')
      }else{
        this.listopen = false
      }
      if(this.listopen === true){
        return this.$message.error('超出可加赠数量')
      }
      let arr = []
      for(let i=0;i<this.goods.length;i++) {
        for (let j = 0; j < this.goods[i].children.length; j++) {
          // 2023 7.16
          if(this.goods[i].children[j].id === 13953 || this.goods[i].children[j].id === 13954){
            if(this.goods[i].children[j].id === 13953 && this.goods[i].children[j].quantity>0){
              if(this.list2[0].quantity+this.list2[1].quantity!= this.FXCN02008num){
                return this.$message.error('产品尺码未选择或产品数量超出')
              } else {
                for(let a=0;a<this.list2.length;a++){
                  if(this.list2[a].quantity >0){
                    // this.order.details.push(this.list2[a])
                    arr.push(this.list2[a])
                  }
                }
              }
            }
            if(this.goods[i].children[j].id === 13954 && this.goods[i].children[j].quantity>0){
              if(this.list3[0].quantity+this.list3[1].quantity!= this.FXCN02009num){
                return this.$message.error('产品尺码未选择或产品数量超出')
              } else {
                for(let a=0;a<this.list3.length;a++){
                  if(this.list3[a].quantity >0){
                    // this.order.details.push(this.list3[a])
                    arr.push(this.list3[a])
                  }
                }
              }
            }
            let idArr = []  //相同id放在同一数组中
            let resultArr = []  //最终结果数组
            for (let i = 0; i < arr.length; i++) {
              let index = idArr.indexOf(arr[i].code)
              if (index > -1) {  //有相同id存在的话,获取index索引位置
                resultArr[index].quantity += arr[i].quantity  //取相同id的value累加
              } else {
                idArr.push(arr[i].code)
                resultArr.push(arr[i])
              }
            }
            resultArr.forEach(v => {
              this.order.details.push(v)
            })
          }
          if(this.goods[i].children[j].id === 13653 && this.goods[i].children[j].quantity > 0 && this.integralrealobject1 === '0'){
            this.details95.name = '积分'
            this.details95.quantity = this.goods[i].children[j].quantity
            this.order.details.push(this.details95)
          }
          if(this.goods[i].children[j].id === 13653 && this.goods[i].children[j].quantity > 0 && this.integralrealobject1 === '1'){
            this.details95.name = '实物'
            this.details95.quantity = this.goods[i].children[j].quantity
            this.order.details.push(this.details95)
          }
          if(this.goods[i].children[j].id === 13654 && this.goods[i].children[j].quantity > 0 && this.integralrealobject2 === '0'){
            this.details96.name = '积分'
            this.details96.quantity = this.goods[i].children[j].quantity
            this.order.details.push(this.details96)
          }
          if(this.goods[i].children[j].id === 13654 && this.goods[i].children[j].quantity > 0 && this.integralrealobject2 === '1'){
            this.details96.name = '实物'
            this.details96.quantity = this.goods[i].children[j].quantity
            this.order.details.push(this.details96)
          }
          if (this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '女神套装') {
            this.ids = this.goods[i].children[j].quantity
            let ids1 = this.details1.quantity*1 + this.details2.quantity*1+this.details3.quantity*1+
                this.details4.quantity*1+this.details5.quantity*1+this.details6.quantity*1+this.details7.quantity*1+this.details8.quantity*1
            let ids2 = this.details9.quantity*1 + this.details10.quantity*1+this.details11.quantity*1+
                this.details12.quantity*1+this.details13.quantity*1
            if(this.ids != ids1 ){
              return  this.$message.error('旋磁智感健康文胸产品数量应与女神套装一致');
            }if(this.ids != ids2){
              return  this.$message.error('远红外塑体衣产品数量应与女神套装一致');
            }
          }
          if (this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '康丽舒络套组') {
            this.ids1 = this.goods[i].children[j].quantity
            let ids3 = this.details14.quantity*1 + this.details15.quantity*1+this.details16.quantity*1+ this.details17.quantity*1
            if(this.ids1 != ids3 ){
              return  this.$message.error('远健康磁性护腰产品数量应与康丽舒络套组一致');
            }
          }
          //  睡眠套组
          if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity > 0){
            if(this.details24.quantity*1 + this.details25.quantity*1 < 1){
              return  this.$message.error('请选择套餐')
            }
            if(this.details24.quantity*1 + this.details25.quantity*1 != this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }
          // 0508纳米微雕3.0套组
          if(this.goods[i].children[j].id === 12856 && this.goods[i].children[j].quantity > 0){
            if(this.details26.quantity*1 + this.details27.quantity*1 +this.details28.quantity*1 < 1){
              return  this.$message.error('请选择套餐')
            }
            if(this.details26.quantity*1 + this.details27.quantity*1 +this.details28.quantity*1 != this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }
          //
          if(this.goods[i].children[j].id === 12873 && this.goods[i].children[j].quantity > 0){
            if(this.radio3 === '0'){
              return  this.$message.error('请选择套餐')
            }
            //家庭理疗金套餐A
            if(this.radio3 === '1'){
              this.details29.quantity = this.goods[i].children[j].quantity
              this.details29.isH0orgood = '0'
              this.details30.quantity = 0
              this.order.details.push(this.details29)
            }
            if(this.radio3 === '2'){
              this.details29.quantity = 0
              this.details30.quantity = this.goods[i].children[j].quantity
              this.details30.isH0orgood = '1'
              this.order.details.push(this.details30)
            }
          }
          if(this.goods[i].children[j].id === 12874 && this.goods[i].children[j].quantity > 0 && 1622563200000 < this.nowTime && this.nowTime < 1628006369000){
            if(this.radio4 === '0'){
              return  this.$message.error('请选择套餐')
            }
            if(this.radio4 === '1'){
              this.details31.quantity = this.goods[i].children[j].quantity
              this.details31.isH0orgood = '0'
              this.details32.quantity = 0
              this.order.details.push(this.details31)
            }
            if(this.radio4 === '2'){
              this.details31.quantity = 0
              this.details32.quantity = this.goods[i].children[j].quantity
              this.details32.isH0orgood = '1'
              this.order.details.push(this.details32)
            }
          }
          //  0608糖宜康套组
          if(this.goods[i].children[j].id === 12878 && this.goods[i].children[j].quantity > 0){
            if(this.goods[i].children[j].quantity !==this.details33.quantity*1+this.details34.quantity*1+this.details35.quantity*1+this.details36.quantity*1+this.details37.quantity*1){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
            if(this.details33.quantity*1+this.details34.quantity*1+this.details35.quantity*1+this.details36.quantity*1+this.details37.quantity*1<1){
              return  this.$message.error('请选择套餐')
            }
          }
          //  0608护膝套组
          // if(this.goods[i].children[j].id === 12879 && this.goods[i].children[j].quantity > 0){
          //   if(this.goods[i].children[j].quantity !==this.details38.quantity*1+this.details39.quantity*1+this.details40.quantity*1){
          //     return  this.$message.error('套餐数量应与购买数量一致')
          //   }
          //   if(this.details38.quantity*1+this.details39.quantity*1+this.details40.quantity*1<1){
          //     return  this.$message.error('请选择套餐')
          //   }
          // }
          //  03023
          if(this.goods[i].children[j].id === 14039 && this.goods[i].children[j].quantity > 0){
            if(this.radio5 === '0'){
              return  this.$message.error('请选择套餐')
            }
            console.log(this.goods[i].children[j],'123')
            //03023
            if(this.radio5 === '1'){
              this.giveStatus = 2
              this.details41.quantity = this.goods[i].children[j].quantity
              this.details41.isH0orgood = '0'
              this.details42.quantity = 0
              this.order.details.push(this.details41)
            }
            if(this.radio5 === '2'){
              this.giveStatus = 1
              this.details41.quantity = 0
              this.details42.quantity = this.goods[i].children[j].quantity
              this.details42.isH0orgood = '1'
              this.order.details.push(this.details42)
            }
          }
          //11693 时光宝盒B
          if(this.goods[i].children[j].id === 11693 && this.goods[i].children[j].quantity > 0){
            if(this.radio12 === '0'){
              return  this.$message('请选择套餐')
            }
            if(this.radio12 === '1'){
              this.details54.quantity = this.goods[i].children[j].quantity
              this.details54.isH0orgood = '0'
              this.details55.quantity = 0
              this.order.details.push(this.details54)
            }
            if(this.radio12 === '2'){
              this.details54.quantity = 0
              this.details55.quantity = this.goods[i].children[j].quantity
              this.details55.isH0orgood = '1'
              this.order.details.push(this.details55)
            }
          }
          //    19258
          if(this.goods[i].children[j].id === 12914 && this.goods[i].children[j].quantity > 0){
            if(this.details47.quantity*1 + this.details48.quantity*1 +this.details49.quantity*1 != this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }
          //    19260
          if(this.goods[i].children[j].id === 12916 && this.goods[i].children[j].quantity > 0){
            if(this.details50.quantity*1 + this.details51.quantity*1 +this.details52.quantity*1 +this.details53.quantity*1!= this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }
          //满300加赠
          // if( (this.Exchangenum > 0) && (this.nowTime < 1630425599000)){
          //   this.order.details.push(this.details56)
          // }
          // if(this.Exchangenum === 0){
          //   this.details56.quantity = '0'
          //   this.order.details.push(this.details56)
          // }

          //TZ（CN)19267  0908健康磁性枕护膝套组
          // if(this.goods[i].children[j].id === 13015 && this.goods[i].children[j].quantity > 0 && this.nowTime < 1631203199000){
          //   this.order.details.push(this.details62)
          //   this.order.details.push(this.details63)
          //   this.order.details.push(this.details64)
          //   this.order.details.push(this.details65)
          //   this.order.details.push(this.details66)
          //   this.order.details.push(this.details67)
          //   if(this.goods[i].children[j].quantity != this.details62.quantity+this.details63.quantity+this.details64.quantity){
          //     return this.$message('套餐数量应与购买数量一致')
          //   }
          //   if(this.goods[i].children[j].quantity != this.details65.quantity+this.details66.quantity+this.details67.quantity){
          //     return this.$message('套餐数量应与购买数量一致')
          //   }
          // }
          //TZ(CN)19273
          if(this.goods[i].children[j].id === 13054 && this.goods[i].children[j].quantity> 0 && this.nowTime < 1633708799000){
            if(this.goods[i].children[j].quantity != (this.details68.quantity+this.details69.quantity+this.details70.quantity+this.details71.quantity+this.details72.quantity) ){
              return this.$message('套餐数量应与购买数量一致')
            }
          }
          if(this.goods[i].children[j].id === 13054 && this.goods[i].children[j].quantity> 0 && this.nowTime < 1633708799000 ){
            if(this.goods[i].children[j].quantity != (this.details73.quantity+this.details74.quantity+this.details75.quantity+this.details76.quantity+this.details77.quantity) ){
              return this.$message('套餐数量应与购买数量一致')
            }
          }
          //TZ(CN)19280  1108健康男内裤男袜套组
          if(this.goods[i].children[j].id === 13095 && this.goods[i].children[j].quantity > 0 && this.nowTime < 1636387199000){
            this.order.details.push(this.details78)
            this.order.details.push(this.details79)
            this.order.details.push(this.details80)
            this.order.details.push(this.details81)
            this.order.details.push(this.details82)
            if(this.goods[i].children[j].quantity != this.details78.quantity + this.details79.quantity + this.details80.quantity + this.details81.quantity + this.details82.quantity){
              return this.$message('套餐数量应与购买数量一致')
            }
          }


          //
          // 60加购
          if(this.goods[i].children[j].id === 13113 && this.goods[i].children[j].quantity > 0 && (1641311999000 > this.nowTime) ){
            if(this.goods[i].children[j].quantity < this.details83.quantity){
              return this.$message('加购数量不能大于购买套餐数量')
            }else{
              this.order.details.push(this.details83)
            }
            // if(this.details83.quantity >0){
            //   this.order.details.push(this.details83)
            // }
          }
          //妇女节 加购
          if(1646150399000 <this.nowTime && this.nowTime < 1647359999000){
             if(
                 this.details94.quantity > (this.num93 + this.num94 + this.num95 +this.num96 +this.num97 + (this.num98*7)) ){
               return this.$message('加购数量不能大于购买套餐数量')
             }else{
               this.order.details.push(this.details94)
             }
          }

          //2023 3.8促销
          if(this.goods[i].children[j].id === 13793 && this.goods[i].children[j].quantity>0){
            if(this.list1[0].quantity+this.list1[1].quantity+this.list1[2].quantity+this.list1[3].quantity+this.list1[4].quantity != this.goods[i].children[j].quantity){
              return this.$message.error('产品尺码未选择或产品数量超出')
            } else {
              // for(let i=0;i<this.list.length;i++){
              //   if(this.list[i].quantity >0){
              //     this.order.details.push(this.list[i])
              //   }
              // }
              for(let a=0;a<this.list1.length;a++){
                if(this.list1[a].quantity >0){
                  this.order.details.push(this.list1[a])
                }
              }
            }
          }

          //  TZ(CN)19288 1208能量围巾套组 12.8 20:00-23:59:59
          // if( (this.goods[i].children[j].id === 13135  && this.goods[i].children[j].quantity >0) || (this.nowTime > 1638979199000) ){
          //   this.order.details.push(this.details84)
          //   this.order.details.push(this.details85)
          //   this.order.details.push(this.details86)
          //   this.order.details.push(this.details87)
          //   this.order.details.push(this.details88)
          //   this.order.details.push(this.details89)
          //   this.order.details.push(this.details90)
          //   this.order.details.push(this.details91)
          //   this.order.details.push(this.details92)
          //   this.order.details.push(this.details93)
          //   if(this.goods[i].children[j].quantity != this.details84.quantity + this.details85.quantity + this.details86.quantity + this.details87.quantity ){
          //     return this.$message('健康磁性护腰套餐数量应与购买数量一致')
          //   }
          //  if(this.goods[i].children[j].quantity != this.details88.quantity + this.details89.quantity + this.details90.quantity){
          //     return this.$message('健康护膝套餐数量应与购买数量一致')
          //   }
          //  if(this.goods[i].children[j].quantity != this.details91.quantity + this.details92.quantity + this.details93.quantity){
          //     return this.$message('能量围巾套餐数量应与购买数量一致')
          //   }
          // }

          for (let b=0;b<this.order.details.length;b++){
            for(let c=b+1;c<this.order.details.length;c++){
              if(this.order.details[b] === this.order.details[c]){
                this.order.details.splice(c,1)
                c--
              }
              if(this.order.details[b].quantity === '0' ||this.order.details[b].quantity === null || this.order.details[b].quantity === undefined
                  || this.order.details[b].quantity === '' || this.order.details[b].quantity === 0 || this.order.details[b].quantity === ""){
                this.order.details.slice(b,1)
              }
            }
          }
          // this.details56.quantity = this.Exchangenum
          if(this.details57.quantity > this.Exchangenum){
            return  this.$message('超出可加购数量')
          }
          if((this.details57.quantity>0) && (this.Exchangenum >0)){
            this.order.details.push(this.details57)
          }

          // 父亲节促销  满980 加400 13175 喜品喜客酒52°2瓶装
          // if(this.goods[i].children[j].id === 13175 && this.goods[i].children[j].quantity > 0){
          //   console.log(this.list1[0])
          //   if(this.list1[0].quantity > this.goods[i].children[j].quantity){
          //     return  this.$message.error('套餐数量应与购买数量一致')
          //   }
          //   if(this.list1[0].quantity > 0 && this.list1[0].quantity <= this.goods[i].children[j].quantity){
          //     this.order.details.push(this.list1[0])
          //   }
          // }
        }
        if(this.details1.quantity>0){
          this.order.details.push(this.details1)
        }
        if(this.details2.quantity>0){
          this.order.details.push(this.details2)
        }
        if(this.details3.quantity>0){
          this.order.details.push(this.details3)
        }
        if(this.details4.quantity>0){
          this.order.details.push(this.details4)
        }
        if(this.details5.quantity>0){
          this.order.details.push(this.details5)
        }
        if(this.details6.quantity>0){
          this.order.details.push(this.details6)
        }
        if(this.details7.quantity>0){
          this.order.details.push(this.details7)
        }
        if(this.details8.quantity>0){
          this.order.details.push(this.details8)
        }
        if(this.details9.quantity>0){
          this.order.details.push(this.details9)
        }
        if(this.details10.quantity>0){
          this.order.details.push(this.details10)
        }
        if(this.details11.quantity>0){
          this.order.details.push(this.details11)
        }
        if(this.details12.quantity>0){
          this.order.details.push(this.details12)
        }
        if(this.details13.quantity>0){
          this.order.details.push(this.details13)
        }
        if(this.details14.quantity>0){
          this.order.details.push(this.details14)
        }
        if(this.details15.quantity>0){
          this.order.details.push(this.details15)
        }
        if(this.details16.quantity>0){
          this.order.details.push(this.details16)
        }
        if(this.details17.quantity>0){
          this.order.details.push(this.details17)
        }
        //
        if(this.details26.quantity>0){
          this.order.details.push(this.details26)
        }
        if(this.details27.quantity>0){
          this.order.details.push(this.details27)
        }
        if(this.details28.quantity>0){
          this.order.details.push(this.details28)
        }

        //家庭理疗金套餐B

        //0608糖宜康套组
        if(this.details33.quantity>0){
          this.order.details.push(this.details33)
        }
        if(this.details34.quantity>0){
          this.order.details.push(this.details34)
        }
        if(this.details35.quantity>0){
          this.order.details.push(this.details35)
        }
        if(this.details36.quantity>0){
          this.order.details.push(this.details36)
        }
        if(this.details37.quantity>0){
          this.order.details.push(this.details37)
        }
        //0608护膝套组
        if(this.details38.quantity>0){
          this.order.details.push(this.details38)
        }
        if(this.details39.quantity>0){
          this.order.details.push(this.details39)
        }
        if(this.details40.quantity>0){
          this.order.details.push(this.details40)
        }

        //满200加购促销
        if(Math.floor((this.total1 + this.total2 + this.total3+this.total4 + this.total5 + this.total6)/200) <
            (this.details43.quantity*1 +this.details44.quantity*1+this.details45.quantity*1+this.details46.quantity*1)) {
          return this.$message.error('请选择正确数量的套餐')
        }
        if(this.details43.quantity>0){
          this.order.details.push(this.details43)
        }
        if(this.details44.quantity>0){
          this.order.details.push(this.details44)
        }
        if(this.details45.quantity>0){
          this.order.details.push(this.details45)
        }
        if(this.details46.quantity>0){
          this.order.details.push(this.details46)
        }
      }
      if(this.details47.quantity>0){
        this.order.details.push(this.details47)
      }
      if(this.details48.quantity>0){
        this.order.details.push(this.details48)
      }
      if(this.details49.quantity>0){
        this.order.details.push(this.details49)
      }
      if(this.details50.quantity>0){
        this.order.details.push(this.details50)
      }
      if(this.details51.quantity>0){
        this.order.details.push(this.details51)
      }
      if(this.details52.quantity>0){
        this.order.details.push(this.details52)
      }
      if(this.details53.quantity>0){
        this.order.details.push(this.details53)
      }

      //TZ(CN)19273  1008健康男内裤套组
      if(this.details68.quantity>0){
        this.order.details.push(this.details68)
      }
      if(this.details69.quantity>0){
        this.order.details.push(this.details69)
      }
      if(this.details70.quantity>0){
        this.order.details.push(this.details70)
      }
      if(this.details71.quantity>0){
        this.order.details.push(this.details71)
      }
      if(this.details72.quantity>0){
        this.order.details.push(this.details72)
      }
      if(this.details73.quantity>0){
        this.order.details.push(this.details73)
      }
      if(this.details74.quantity>0){
        this.order.details.push(this.details74)
      }
      if(this.details75.quantity>0){
        this.order.details.push(this.details75)
      }
      if(this.details76.quantity>0){
        this.order.details.push(this.details76)
      }
      if(this.details77.quantity>0){
        this.order.details.push(this.details77)
      }


      for (let b=0;b<this.order.details.length;b++){
        for(let c=b+1;c<this.order.details.length;c++){
          if(this.order.details[b] === this.order.details[c]){
            this.order.details.splice(c,1)
            c--
          }
          if(this.order.details[b].quantity === '0' ||this.order.details[b].quantity === null || this.order.details[b].quantity === undefined
              || this.order.details[b].quantity === '' || this.order.details[b].quantity === 0 || this.order.details[b].quantity === ""){
            this.order.details.splice(b,1)
          }
        }
      }

//养生睡眠
      if(this.details24.quantity>0){
        this.order.details.push(this.details24)
      }
      if(this.details25.quantity>0){
        this.order.details.push(this.details25)
      }
      for (let b=0;b<this.order.details.length;b++){
        for(let c=b+1;c<this.order.details.length;c++){
          if(this.order.details[b] === this.order.details[c]){
            this.order.details.splice(c,1)
            c--
          }
          if(this.order.details[b].quantity === '0' ||this.order.details[b].quantity === null || this.order.details[b].quantity === undefined
              || this.order.details[b].quantity === '' ||this.order.details[b].quantity === 0 ){
            this.order.details.splice(b,1)
          }
        }
      }
      for(let v =0;v<this.order.details.length;v++){
        if(this.order.details[v].quantity === 0){
          this.order.details.splice(v,1)
          v--

        }
      }

      if (!this.order.details || !this.order.details.length) {
        this.$message.error('至少要选择一个商品')
      } else {
        this.activeIndex = 2
        // isPaymentPassword().then(res => {
        //   if (res.data) {
        //     this.$router.push({path: '/two-password'})
        //   } else {
        //     this.activeIndex = 2
        //   }
        // }).catch(err => {
        //   console.error(err)
        // })
      }
    },
    handleQuantityChange(row){
      console.log(row)
      // if(row.quantity >0){
      //   this.order.details.push(row)
      // }
      // let quantitys = 0
      // for(let i=0;i<this.list.length;i++){
      //   quantitys += this.list[i].quantity
      //   if(this.Exchangenum < quantitys){
      //     this.listopen = true
      //     return this.$message.error('超出可加赠数量')
      //   }else{
      //     this.listopen = false
      //   }
      // }
    },
    onForward2() {
      this.activeIndex = 1
      this.getGoodsData()
      this.order.details = []
    },
    onSubmit() {
      if(this.radio5 === '2' && this.radio3 === '2'){
        this.abcd = 1;
      }
      if(this.radio5 === '2' && this.radio4 === '2'){
        this.abcd = 2
      }
      if(this.radio5 === '2' && this.radio4 === '2' && this.radio3 === '2'){
        this.abcd = 3
      }
      for(let i = 0;i<this.order.details.length;i++){
        if(this.order.details[i].id === 13653 && this.integralrealobject1 === '0'){
            this.order.details[i].fenOrCode = 0
        }
        if(this.order.details[i].id === 13653 && this.integralrealobject1 === '1'){
          this.order.details[i].fenOrCode = 1
        }
        if(this.order.details[i].id === 13654 && this.integralrealobject2 === '0'){
          this.order.details[i].fenOrCode = 0
        }
        if(this.order.details[i].id === 13654 && this.integralrealobject2 === '1'){
          this.order.details[i].fenOrCode = 1
        }
      }
      console.log(this.order.details,'this.order.details')
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.order.details.forEach(v=>{
            if(v.id===14039){
              v.giveStatus = this.giveStatus
            }
          })
          const details = this.order.details.map(ele => {
            return {
              goodsId: ele.id,
              quantity: ele.quantity,
              isH0orgood:ele.isH0orgood,
              price:ele.price,
              giveStatus:ele.giveStatus,
              fenOrCode:ele.fenOrCode
            }
          })
          // 促销字段isRefresh  非促销时去掉
          createOrder({
            isRefresh: true,
            memberNo: this.order.memberNo,
            isH0orgood: this.order.isH0orgood,
            giveStatus: this.order.giveStatus,
            abcd: this.abcd,
            agentNo: this.isAgent ? this.userCode : '',
            orderType: 19,
            password: this.order.password,
            details
          }).then(res => {
            if (res.data === '刷新') {
              this.$alert('未成功!需要刷新页面!', '', {
                confirmButtonText: '确定',
                callback: () => {
                  window.location.reload()
                }
              })
              return
            }
            const { orderNo } = res.data
            this.orderNo = orderNo
            this.loading = false
            this.activeIndex = 3
            this.memberNo = ''
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onBackHome() {
      this.$router.push('/order/index')
    },
    onContinue() {
      location.reload()
      if (this.isAgent) {
        this.activeIndex = 0
      } else {
        this.activeIndex = 1
        this.goods = []
        this.getGoodsData()
      }
      this.order = {
        memberNo: undefined,
        password: undefined,
        details: []
      }
      this.$nextTick(() => {
        this.$refs['orderForm'].resetFields()
      })
    },
    orderDetailSummaries(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 5) {
          sums[index] = this.amount
        } else if (index === 6) {
          sums[index] = this.pv
        }
      })
      return sums
    },
    handleNoCheck(val) {
      if (val) {
        searchMember({ userCode: val.trim().toUpperCase() }).then(res => {
          if (res.data.length > 0) {
            this.memberNo = res.data[0].userName
          } else  {
            this.memberNo = '请输入正确的订货人编号'
          }
        })
        checkNo({'memberNo':this.order.memberNo}).then(res=>{
          if(res.data === '0'){
            this.checkNo = true
            this.open13 = true
          }else if(res.data === '2'){
            this.checkNo = '2'
            this.list = this.list.splice(0,1)
            this.open13 = true
          } else {
            this.checkNo = false
            this.open13 = false
          }
        }).catch(err=>{
          console.error(err)
        })
      } else {
        this.memberNo = ''
      }
    }
  }
}
</script>

<style lang="scss">
.order-wrapper {
  .radiocssitem {
    el-form-item__content {
      margin-left: 10px;
    }
  }
  .step2 {
    .summary {
      padding: 10px;
      font-size: 15px;
      font-weight: bold;
      span {
        padding-right: 20px;
        em {
          padding-left: 10px;
          font-size: 16px;
          font-style: normal;
          color: #FF0000;
        }
      }
    }
  }
  .step3 {
    .amt {
      color: #008800;
      font-weight: bold;
    }
  }
  .step4 {
    .success {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        max-height: 120px;
      }
      .success-txt {
        display: inline-block;
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;
        width: 420px;
        span {
          color: #FF0000;
          font-size: 16px;
        }
      }
      .el-button {
        margin-top: 20px;
      }
    }
  }
  .redspan {
    color: red;
  }
}
</style>
